import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Modal, Button, Dimmer, Loader, Segment, Popup } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import { MediumApi } from 'trill-api-admin-client'

import ApiErrorMessage from './ApiErrorMessage'
import FormErrorLabel from './FormErrorLabel'
import CancelablePromise from '../CancelablePromise'
import LogLevel from '../LogLevel'

const logger = LogLevel.getLogger('MediumItemEditModal')
const mediumApi = new MediumApi()
let sendMediumItem

const propTypes = {
  /**
   * 紐づく元のメディア
   */
  medium: PropTypes.object,

  /**
   * 編集対象の メディアの設定 (作成の場合は空)
   */
  mediumItem: PropTypes.object,

  /**
   * モーダルの表示状態
   */
  open: PropTypes.bool,

  /**
   * モーダルを閉じたときに呼び出す外部関数
   */
  onClose: PropTypes.func,
}

const defaultProps = {
  mediumItem: {},
  open: false,
}

class MediumItemEditModal extends Component {
  state = {
    isFormValid: false,
    isFormModified: false,
    isBusy: false,
    apiError: null,

    autoPublish: false,
    autoPublishCpUpdate: false,
  }

  /**
   * 初期のフォーム入力データ
   */
  initialFormValues = {}

  /**
   * フォームの入力データが初期化されてるかどうか
   */
  isFormResetted = false

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.mediumItem, nextProps.mediumItem)) {
      this.setState({
        isFormModified: false,
        mediumId: _.get(nextProps, 'mediumItem.media.id', null),
        autoPublish: _.get(nextProps, 'mediumItem.autoPublish', false),
        autoPublishCpUpdate: _.get(nextProps, 'mediumItem.autoPublishCpUpdate', false),
      })

      this.isFormResetted = false
    }
  }

  // eslint-disable-next-line
  componentDidUpdate(prevProps, prevState) {
    if (this.refs.form && !this.isFormResetted) {
      if (this.props.mediumItem) {
        this.initializeFormValues(this.props.mediumItem)

        this.refs.form.reset(this.initialFormValues)
      } else {
        this.refs.form.reset({})
      }

      this.isFormResetted = true
    }
  }

  // eslint-disable-next-line
  componentWillUnmount() {
    if (!_.isNil(sendMediumItem)) {
      sendMediumItem.cancel()
    }
  }

  /**
   * フォームの値を変更したときのハンドラ
   */
  // eslint-disable-next-line
  handleFormChange = (currentValues, isChanged) => {
    const partialState = { isFormModified: false }

    // 現在のフォームデータを初期のフォームデータと比較
    _.each(currentValues, (value, key) => {
      if (this.initialFormValues[key] !== value) {
        partialState.isFormModified = true
      }
    })

    this.setState(partialState)
  }

  /**
   * フォームの値が妥当なときに呼び出されるハンドラ
   */
  handleFormValid = () => {
    this.setState({ isFormValid: true })
  }

  handleAutoPublishToggle = (event, { checked }) => {
    this.setState({
      autoPublish: checked,
      autoPublishCpUpdate: checked ? false : this.state.autoPublishCpUpdate,
    })
  }

  handleAutoPublishCpUpdateToggle = (event, { checked }) => {
    this.setState({
      autoPublishCpUpdate: checked,
    })
  }

  /**
   * フォームの値が無効のときに呼び出されるハンドラ
   */
  handleFormInvalid = () => {
    this.setState({ isFormValid: false })
  }

  /**
   * フォームの値を送信したときのハンドラ
   */
  handleFormValidSubmit = (submitFormData, resetForm) => {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    sendMediumItem = CancelablePromise(this.sendData(this.getRequestParameters(submitFormData)))
    sendMediumItem.promise
      .then(response => {
        const mediumItem = response.data

        this.initializeFormValues(mediumItem)

        resetForm(this.initialFormValues)

        this.setState(
          {
            isFormModified: false,
            isBusy: false,
          },
          () => {
            if (_.isEmpty(this.props.mediumItem) && this.props.onClose) {
              this.props.onClose()
            }
          },
        )
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('handle form submit error', error)

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * キャンセルボタンを押したときのハンドラ
   */
  handleCancelButtonClick = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  /**
   * 保存・更新ボタンを押したときのハンドラ
   */
  handleSaveButtonClick = () => {
    this.refs.form.submit()
  }

  /**
   * 閉じるボタンを押したときのハンドラ
   */
  handleModalClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  /**
   * フォームの初期化
   * @param {Object} mediumItem メディアの設定データ
   */
  initializeFormValues(mediumItem) {
    this.initialFormValues.name = mediumItem.name
    this.initialFormValues.hotFactor = _.defaultTo(mediumItem.hotFactor, '').toString()
    this.initialFormValues.url = mediumItem.url
    this.initialFormValues.retentionPeriod = _.defaultTo(mediumItem.retentionPeriod, '').toString()
    this.initialFormValues.autoPublish = _.defaultTo(mediumItem.autoPublish, false)
    this.initialFormValues.autoPublishCpUpdate = _.defaultTo(mediumItem.autoPublishCpUpdate, false)
    this.initialFormValues.ignoreCopyrightWarning = _.defaultTo(mediumItem.ignoreCopyrightWarning, false)
    this.initialFormValues.shannonSubmissionAllowed = _.defaultTo(mediumItem.shannonSubmissionAllowed, false)
    this.initialFormValues.classifyCategory = _.defaultTo(mediumItem.classifyCategory, false)

    logger.debug('initialize form values', this.initialFormValues, { mediumItem })
  }

  /**
   * API にデータを送信
   */
  sendData = submitMediumItemData => {
    if (_.isEmpty(this.props.mediumItem)) {
      return mediumApi.postMediumItem(submitMediumItemData)
    }

    const mediumItemId = this.props.mediumItem.id
    return mediumApi.patchMediumItem(mediumItemId, { mediumItemUpdateValues: submitMediumItemData })
  }

  /**
   * API 通信時のリクエストパラメータを取得
   */
  getRequestParameters = submitFormData => {
    // 差分を取得する関数
    const difference = (object, base) => {
      const changes = (_object, _base) =>
        _.transform(_object, (result, value, key) => {
          if (!_.isEqual(value, _base[key])) {
            result[key] = _.isObject(value) && _.isObject(_base[key]) ? changes(value, _base[key]) : value
          }
        })
      return changes(object, base)
    }

    // medium id
    submitFormData.mediumId = this.props.medium.id

    // 送信データに空文字があれば 0 を入れる
    const submitRetentionPeriod = submitFormData.retentionPeriod
    submitFormData.retentionPeriod = _.isEmpty(submitRetentionPeriod) ? 0 : parseInt(submitRetentionPeriod, 10)
    const submitHotFactor = submitFormData.hotFactor
    submitFormData.hotFactor = _.isEmpty(submitHotFactor) ? 0 : parseInt(submitHotFactor, 10)

    // 変更前のフォームの値
    let initialFormValues = {}
    const initialData = {}

    if (!_.isEmpty(this.props.mediumItem)) {
      initialFormValues = this.initialFormValues
      initialData.name = initialFormValues.name
      initialData.hotFactor = initialFormValues.hotFactor
      initialData.url = initialFormValues.url
      initialData.retentionPeriod = _.isEmpty(initialFormValues.retentionPeriod)
        ? 0
        : parseInt(initialFormValues.retentionPeriod, 10)
      initialData.hotFactor = _.isEmpty(initialFormValues.hotFactor) ? 0 : parseInt(initialFormValues.hotFactor, 10)

      initialData.autoPublish = initialFormValues.autoPublish
      initialData.autoPublishCpUpdate = initialFormValues.autoPublishCpUpdate
      initialData.ignoreCopyrightWarning = initialFormValues.ignoreCopyrightWarning
      initialData.shannonSubmissionAllowed = initialFormValues.shannonSubmissionAllowed
      initialData.classifyCategory = initialFormValues.classifyCategory
    }

    // フォームから送られてきたデータと初期データの差分を取得
    const requestParameters = difference(submitFormData, initialData)

    if (_.has(requestParameters, 'url')) {
      _.set(requestParameters, 'retentionPeriod', parseInt(submitFormData.retentionPeriod, 10))
    }

    if (_.has(requestParameters, 'hotFactor')) {
      _.set(requestParameters, 'hotFactor', parseInt(submitFormData.hotFactor, 10))
    }

    // url に空文字に設定されていた場合はリセット
    if (_.isEqual(_.get(requestParameters, 'url'), '')) {
      _.set(requestParameters, 'url', ' ')
    }

    logger.debug('get request parameters', {
      requestParameters,
      initialData,
      submitFormData,
    })

    return _.omitBy(requestParameters, v => !_.isBoolean(v) && !_.isNumber(v) && _.isEmpty(v))
  }

  render() {
    return (
      <Modal
        className="MediumItemEditModal"
        size="small"
        closeIcon
        open={this.props.open}
        onClose={this.handleModalClose}
        closeOnDimmerClick={false}
      >
        <Modal.Header>メディア設定の{_.isEmpty(this.props.mediumItem) ? '作成' : '編集'}</Modal.Header>

        {/* ローディング */}
        <Dimmer active={this.state.isBusy} inverted>
          <Loader />
        </Dimmer>

        <Modal.Content>
          {/* エラーメッセージ */}
          <ApiErrorMessage error={this.state.apiError} />

          <Form
            ref="form"
            noValidate
            onChange={this.handleFormChange}
            onValid={this.handleFormValid}
            onInvalid={this.handleFormInvalid}
            onValidSubmit={this.handleFormValidSubmit}
          >
            {/* メディア名入力フィールド */}
            <Form.Input
              name="name"
              label="メディアの設定名"
              placeholder="メディアの設定名を入力してください"
              required
            />

            {/* フィード入力フィールド */}
            <Form.Input
              name="url"
              label="フィード"
              placeholder="フィード URL を入力してください"
              validations="isUrl"
              validationErrors={{ isUrl: '無効な URL です' }}
              errorLabel={<FormErrorLabel />}
            />

            <Form.Group widths="equal">
              {/* 重み付けフィールド */}
              <Form.Input
                label="重み付け"
                placeholder="重み付け"
                name="hotFactor"
                type="number"
                validations={{
                  isNumeric: true,
                }}
              />

              {/* 記事保持期間フィールド */}
              <Form.Input
                label="記事保持期間(日)"
                placeholder="記事保持期間(日)"
                name="retentionPeriod"
                type="number"
                validations={{
                  isNumeric: true,
                }}
              />
            </Form.Group>

            {/* 記事の自動公開選択フィールド */}
            <Segment>
              <Popup
                trigger={
                  <Form.Checkbox
                    toggle
                    label="記事の自動公開"
                    name="autoPublish"
                    checked={this.state.autoPublish}
                    onChange={this.handleAutoPublishToggle}
                  />
                }
                inverted
                content="記事の取り込みと同時に公開します"
              />
            </Segment>

            {/* CP修正による記事保留を無視 */}
            <Segment
              style={{
                display: this.state.autoPublish ? 'none' : 'block',
              }}
            >
              <Popup
                trigger={
                  <Form.Checkbox
                    toggle
                    label="CP修正による記事保留を無視"
                    name="autoPublishCpUpdate"
                    value={this.state.autoPublishCpUpdate}
                    onChange={this.handleAutoPublishCpUpdateToggle}
                  />
                }
                inverted
                content="CP側で調整があった場合も、保留せずにそのまま公開します。"
              />
            </Segment>

            {/* 画像の出典を表示するかどうかを選択するフィールド */}
            <Segment>
              <Popup
                trigger={
                  <Form.Checkbox
                    toggle
                    label="著作権情報がない場合でも、記事を保留せず公開"
                    name="ignoreCopyrightWarning"
                  />
                }
                inverted
                content="記事の取り込みの際に、一部のコンテンツに著作権情報が欠けていても保留状態にせず公開します"
              />
            </Segment>

            {/* シャノン連携許可選択フィールド */}
            <Segment>
              <Form.Checkbox toggle label="Shannon 連携を許可" name="shannonSubmissionAllowed" />
            </Segment>

            {/* 自動カテゴリ振り分け選択フィールド */}
            <Segment>
              <Form.Checkbox toggle label="記事を自動でカテゴリに振り分ける" name="classifyCategory" />
            </Segment>
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button content="キャンセル" onClick={this.handleCancelButtonClick} />

          <Button
            positive
            content={_.isEmpty(this.props.mediumItem) ? '保存' : '更新'}
            onClick={this.handleSaveButtonClick}
            disabled={!this.state.isFormValid || !this.state.isFormModified}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

MediumItemEditModal.propTypes = propTypes
MediumItemEditModal.defaultProps = defaultProps

export default MediumItemEditModal
